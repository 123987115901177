import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/lmg.jpg'
import IMG2 from '../../assets/worldkite.jpg'
import IMG3 from '../../assets/hc.jpg'
import IMG4 from '../../assets/tyre.jpg'
import IMG5 from '../../assets/atarapay.jpg'
import IMG6 from '../../assets/tyc.jpg'


const Portfolio = () => {

  const data = [
    {
      
      id: 1,
      image: IMG1,
      title: "Lumia",
      github: "https://github.com/Arnold-Omagbemi",
      demo: "https://lumia.ng/",  
  },
  {
    id: 2,
  image: IMG2,
  title: "World Kite",
  github: "https://github.com/Arnold-Omagbemi",
  demo: "https://www.worldkite.co.uk/",
},
{
  id: 3,
  image: IMG3,
  title: "Hendon College",
  github: "https://github.com/Arnold-Omagbemi",
  demo: "https://www.hendoncollege.org/",
   
},
{
  id: 4,
  image: IMG4,
  title: "Tyre Links",
  github: "https://github.com/Arnold-Omagbemi",
  demo: "https://www.tyrelinks.com/",
   
},
{
  id: 5,
  image: IMG5,
  title: "AtaraPay",
  github: "https://github.com/Arnold-Omagbemi",
  demo: "https://www.atarapay.com/",
   },
{
  id: 6,
      image: IMG6,
      title: "Things Around Your Arm",
      github: "https://github.com/Arnold-Omagbemi",
      demo: "https://thingsaroundyourarm.com/",
},
  ]

  return (
    <section id='portfolio' className='myportfolio'>
      <h2>Selected Projects</h2>
      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
      <article key={id} className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={image} alt={title} />
          </div>
          <h3>{title}</h3>
          <div className="portfolio__item-cta">
          <a href={github} className="btn" target="_blank" rel="noopener
            noreferrer">Github</a>
          <a href={demo} className="btn btn-primary" target="_blank" rel="noopener
            noreferrer">Live Demo</a>
          </div>
        </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio

